<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%"
    class="nsi-technical-reason"
    :close-on-click-modal="false"
  >
    <div slot="title">
      Фирменные устройства РЗА
      <template v-if="userCanEdit">
        <el-button v-if="!editMode" class="back_action_panel--first_element" @click="handleEdit"
                   type="text">
          <i class="el-icon-edit"></i> Редактировать
        </el-button>
        <el-button v-if="editMode" class="back_action_panel--first_element custom-button "
                   @click="handleSave" type="text">
          <SaveIcon/>
          Сохранить
        </el-button>
        <el-button @click="handleDelete(productAssetModel.id)"
                   :disabled="productAssetModel.isAip || productAssetModel.id == null" type="text"
                   icon="el-icon-delete">
          Удалить
        </el-button>
      </template>
    </div>
    <el-form :rules="rules" v-loading="loading" ref="productAssetModelForm"
             :model="productAssetModel" label-width="12rem">
      <el-form-item prop="isAip" label="СК-11:">
        <el-checkbox
          v-model="productAssetModel.isAip"
          :disabled="true">
        </el-checkbox>
      </el-form-item>

      <el-form-item prop="name" label="Наименование:">
        <el-input
          v-model="productAssetModel.name"
          :disabled="!editMode || productAssetModel.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="producerId" label="Производитель:">
        <el-select v-model="productAssetModel.producerId"
                   :disabled="!editMode || productAssetModel.isAip" filterable clearable>
          <el-option
            v-for="item in producerOrganizations"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="executionId" label="Исполнение:">
        <el-select v-model="productAssetModel.executionId" :disabled="!editMode" filterable
                   clearable>
          <el-option
            v-for="item in executions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="transmissionMediumId" style="word-break: normal"
                    label="Среда передачи информации:" v-loading="loading">
        <el-select clearable v-model="productAssetModel.transmissionMediumId"
                   :disabled="!editMode" placeholder="Выберите">
          <el-option v-for="it in transmissionMediums"
                     :value="it.id"
                     :key="it.id"
                     :label="it.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-row>
        <el-col :span="24">
          <el-table
            style="width: 100%"
            :data="productAssetModel.softwareVersions"
            :disabled="!editMode"
          >
            <el-table-column prop="softwareVersion"  min-width="2"   label="Версия ПО:">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.softwareVersion"
                  size="mini"
                  :disabled="!editMode"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="utcSoftwareVersionDt" min-width="2" label="Дата версии:">
              <template  v-slot="scope">
                <el-date-picker
                  type="date"
                  size="mini"
                  format="dd.MM.yyyy"
                  v-model="scope.row.utcSoftwareVersionDt"
                  v-mask="'##.##.####'"
                  placeholder="дд.мм.гггг"
                  :disabled="!editMode"
                  :picker-options="datePickerOptions">
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column align="right" min-width="1"  v-if="editMode">
              <template v-slot:header>
                <el-button
                  type="text"
                  icon="el-icon-circle-plus-outline"
                  class="p-1 mb-1 ml-2"
                  @click="addNewSoftwareVersion"
                >Добавить</el-button>
              </template>
              <template v-slot="scope">
                <el-button v-if="editMode" @click="deleteSoftwareVersion(scope.$index)" type="text"
                           icon="el-icon-delete"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import productAssetModelsApi from '@/api/nsi/productAssetModels';
import SaveIcon from '@/assets/icons/save.svg';
import dateHelpers from '@/mixins/dateHelpers';
import transmissionMediumsApi from '@/api/nsi/transmissionMediums';
import formatters from '@/mixins/formatters';
import moment from 'moment';

export default {
  name: 'ProductAssetModelModal',
  mixins: [dateHelpers, formatters],
  props: ['value', 'productAssetModel'],
  computed: {
    ...mapGetters('dictionaries', ['producerOrganizations', 'executions']),
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup']),
    userCanEdit() {
      return this.userHasRoleGroup('LevelIa');
     // return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
  },
  components: { SaveIcon },
  data() {
    return {
      editMode: false,
      loading: false,
      transmissionMediums: [],
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        producerId: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
      },
    };
  },
  // eslint-disable-next-line no-empty-function
  async mounted() {
    this.$root.$on('addEvent', (x) => {
      this.editMode = x.editMode;
    });
    this.loading = true;
    try {
      const tr = await transmissionMediumsApi.getTransmissionMediumsWithPagination(1, 1000000, null);
      this.transmissionMediums = tr.data.items;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    addNewSoftwareVersion() {
      this.productAssetModel.softwareVersions.unshift({
        softwareVersion: '',
        utcSoftwareVersionDt: null,
      });
    },
    deleteSoftwareVersion(idx) {
      this.$delete(this.productAssetModel.softwareVersions, idx);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleEdit() {
      this.editMode = true;
    },
    async addProductAssetModel(productAssetModel) {
      await productAssetModelsApi.addProductAssetModel(productAssetModel);
      this.$emit('on-create');
    },
    async updateProductAssetModel(productAssetModel) {
      await productAssetModelsApi.updateProductAssetModel(productAssetModel);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.productAssetModelForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;

      const request = this.productAssetModel.id ? this.updateProductAssetModel : this.addProductAssetModel;
      this.loading = true;
      if (this.productAssetModel.softwareVersions && this.productAssetModel.softwareVersions.length) {
        this.productAssetModel.softwareVersions.forEach((el) => {
          el.utcSoftwareVersionDt = el.utcSoftwareVersionDt ? moment(el.utcSoftwareVersionDt).format('YYYY-MM-DD') : null;
        });
      }
      await request(this.productAssetModel);
      this.dialogVisible = false;
      this.loading = false;
    },
    handleDelete(id) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;
        const res = await productAssetModelsApi.deleteProductAssetModel(id);
        if (!res.data.succeeded) {
          this.$alert(res.data.resultMessage, 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
          });
        } else {
          this.$emit('on-delete');
          this.dialogVisible = false;
        }

        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
