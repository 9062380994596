<template>
  <div class="my-flex-container-column">
    <div>
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline">Добавить
        </el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text"
                  :loading="loading"
                   icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleMergeMode" type="text" icon="el-icon-share">Объединить</el-button>
      </el-col>
      <el-col v-if="userCanEdit && mergeMode" :span="3">
        <el-button @click="handleMergeModeCancel" type="text" icon="el-icon-circle-close">Отменить
        </el-button>
      </el-col>
      <pagination
        style="text-align: center"
        :total-count="totalCount"
        :page-size.sync="pageSize"
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <div>
      <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё
      </el-button>

    </div>
    <div class="my-flex-data-content">
      <el-table
        v-loading="loading"
        style="width: 100%"
        ref="table"
        height="100%"
        :data="productAssetModels"
        :row-key="getTableKey"
        :default-sort="{prop: 'name', order: 'ascending'}"
        @sort-change="handleSortChange">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
              :data="scope.row.softwareVersions"
            >
              <el-table-column
                prop="softwareVersion"
                label="Версия ПО"
                sortable
                min-width="1">
              </el-table-column>
              <el-table-column
                prop="utcSoftwareVersionDt"
                label="Дата версии"
                sortable
                min-width="1">
                <template slot-scope="scope2">
                  {{
                    scope2.row.utcSoftwareVersionDt ? moment(scope2.row.utcSoftwareVersionDt).format('DD.MM.YYYY') : ''
                  }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column width="64">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-search" v-if="!mergeMode"
                       @click="handleView(scope.$index, scope.row)"></el-button>
            <el-checkbox v-if="mergeMode" v-model="scope.row.isMerging"
                         @change="handleIsMergingChange(scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="Наименование"
          sortable="custom"
          min-width="2">
        </el-table-column>
        <el-table-column
          prop="producerName"
          label="Производитель"
          sortable="custom"
          min-width="2"
          :formatter="producerRenderer">
        </el-table-column>
        <el-table-column
          prop="executionName"
          label="Исполнение"
          sortable="custom"
          width="130">
        </el-table-column>
        <el-table-column
          prop="transmissionMediumCode"
          label="Среда передачи информации"
          sortable="custom"
          min-width="2">
        </el-table-column>
        <el-table-column
          prop="isAip"
          label="СК-11"
          sortable="custom"
          width="80"
          :formatter="boolRenderer">
        </el-table-column>
        <el-table-column v-if="userCanEdit" label="" width="50">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-delete" :disabled="scope.row.isAip"
                       @click="handleDelete(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <product-asset-model-modal @on-update="getProductAssetModels()"
                                 @on-create="getProductAssetModels()"
                                 @on-delete="getProductAssetModels()"

                                 v-model="dialogVisible"
                                 :productAssetModel="dialogProductAssetModel">
      </product-asset-model-modal>
      <product-asset-model-merge-modal v-model="dialogMergeVisible" :items="mergingItems"
                                  @on-merge="handleMerge($event)"
                                  @on-merge-item-remove="handleMergeItemRemove($event)"></product-asset-model-merge-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import productAssetModelsApi from '@/api/nsi/productAssetModels';
import pagination from '@/components/Ui/Pagination';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import formatters from '@/mixins/formatters.js';
import ProductAssetModelModal from './ProductAssetModelModal';
import ProductAssetModelMergeModal from './ProductAssetModelMergeModal';


export default {
  name: 'ProductAssetModelsTable',
  props: {
    filterModel: {
      type: Object,
      default: () => {
      }
    },
    filterActive: { type: Boolean, default: () => true },
  },
  mixins: [formatters, xlsUpDownHelper],
  components: {
    pagination,
    ProductAssetModelModal,
    ProductAssetModelMergeModal
  },
  data() {
    return {
      loading: false,
      productAssetModels: [],
      pageNumber: 1,
      pageSize: 50,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogProductAssetModel: {},
      toggleRow: false,
      mergeMode: false,
      mergingItems: [],
      dialogMergeVisible: false
    };
  },
  watch: {
    filterModel: {
      handler() {
        this.getProductAssetModels();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup']),
    userCanEdit() {
      return this.userHasRoleGroup('LevelIa');
      //    return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
  },
  async mounted() {
    // this.$nextTick(() => {
    //   this.tableHeight = this.getTableHeight();
    // });
    await this.getProductAssetModels();
    //  this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const { table } = this.$refs;
      const tableColumns = table.store.states.columns;
      const res = await productAssetModelsApi.getProductAssetModelsWithPagination(
        this.pageNumber,
        this.totalCount,
        this.filterModel.nameFilter,
        this.filterModel.producerName,
        this.filterModel.executionName,
        this.filterModel.isLocalNsiFilter,
        this.filterModel.transmissionMediumIdFilter,
        this.sortField,
        this.sortDesc
      );
      if (res.data) {
        await this.exportToExcel('Фирменные устройства РЗА', res.data.items, tableColumns);
      }
      this.loading = false;
    },
    async getProductAssetModels() {
      this.loading = true;
      const res = await productAssetModelsApi.getProductAssetModelsWithPagination(
        this.pageNumber,
        this.pageSize,
        this.filterModel.nameFilter,
        this.filterModel.producerName,
        this.filterModel.executionName,
        this.filterModel.isLocalNsiFilter,
        this.filterModel.transmissionMediumIdFilter,
        this.sortField,
        this.sortDesc
      );
      if (res.data) {
        this.productAssetModels = res.data.items;
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    // eslint-disable-next-line no-unused-vars
    handleCreate(index, productAssetModel) {
      this.dialogProductAssetModel = {
        id: null,
        name: null,
        producerId: null,
        exectuionId: null,
        isAip: false,
        aipId: null,
        softwareVersions: []
      };
      this.$root.$emit('addEvent', {
        editMode: true,
      });
      this.dialogVisible = true;
    },
    handleDelete(index, pam) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;

        const res = await productAssetModelsApi.deleteProductAssetModel(pam.id);
        if (!res.data.succeeded) {
          this.$alert(res.data.resultMessage, 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          await this.getProductAssetModels();
        }

        this.loading = false;
      });
    },
    async handleView(index, row) {
      this.dialogProductAssetModel = row;
      this.$root.$emit('addEvent', {
        editMode: false,
      });
      this.dialogVisible = true;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getProductAssetModels();
    },
    async handleCurrentChange() {
      await this.getProductAssetModels();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getProductAssetModels();
    },
    getTableKey(row) {
      return row.id;
    },
    toggleAllNodes() {
      this.toggleRow = !this.toggleRow;
      if (this.$refs.table.data) {
        this.$refs.table.data.forEach((el) => {
          this.$refs.table.store.toggleRowExpansion(el, this.toggleRow);
        });
      }

      // this.applyChecking();
    },
    handleMergeItemRemove(item) {
      const index = this.mergingItems.findIndex((x) => x.id === item.id);
      if (index > -1) {
        this.mergingItems.splice(index, 1);
        this.applyIsMerging();

        if (this.mergingItems.length <= 1) {
          this.dialogMergeVisible = false;
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        }
      }
    },
    applyIsMerging() {
      for (let i = 0; i < this.productAssetModels.length; i++) {
        const item = this.productAssetModels[i];
        this.$set(item, 'isMerging', this.mergingItems.some((x) => x.id === item.id));
      }
    },
    async handleMerge(data) {
      if (data.secondaryItems.some((x) => x.isAip)) {
        this.$alert('Невозможно объединить несколько корпоративных записей или использовать корпоративную запись в качестве присоединяемой', 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
        return;
      }

      this.loading = true;

      const res = await productAssetModelsApi.mergeProductAssetModels(data.mainItem.id, data.secondaryItems.map((x) => x.id));
      if (!res.data.succeeded) {
        this.$alert(res.data.resultMessage, 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      } else {
        this.dialogMergeVisible = false;
        this.handleMergeModeCancel();
        await this.getProductAssetModels();
      }
      this.loading = false;
    },
    handleIsMergingChange(item) {
      if (!this.mergingItems.some((x) => x.id === item.id)) {
        this.mergingItems.push(item);
      } else {
        const index = this.mergingItems.findIndex((x) => x.id === item.id);
        if (index > -1) {
          this.mergingItems.splice(index, 1);
        }
      }
    },
    handleMergeMode() {
      if (!this.mergeMode) {
        this.mergeMode = true;
      } else {
        if (this.mergingItems.length <= 1) {
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          this.dialogMergeVisible = true;
        }
      }
    },
    handleMergeModeCancel() {
      this.mergingItems.splice(0);
      this.applyIsMerging();
      this.mergeMode = false;
    }
  }
};
</script>

<style scoped>

</style>
